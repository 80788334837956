<template>
  <div>
    <v-card
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Modifier une certification
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiCertificateOutline }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-skeleton-loader
        v-if="loading"
        type="article, actions"
      ></v-skeleton-loader>
      <template v-else>
        <v-card-text class="mt-3">
          <v-row>
            <v-col>
              <v-text-field
                v-model="certification.name"
                label="Nom de la certification"
                outlined
                dense
                placeholder="Nom de la certification"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="certification.certifier"
                label="Organisme certifiant"
                outlined
                dense
                placeholder="ETS Global"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="certification.cost"
                type="number"
                label="Coût de la certification"
                outlined
                suffix="€"
                dense
                placeholder="150"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-dialog
            v-model="dialog"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                color="error"
                v-bind="attrs"
                outlined
                v-on="on"
              >
                Supprimer la certification
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Attention
              </v-card-title>

              <v-card-text>
                La suppression est définitive
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="deleteCertification"
                >
                  Supprimer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            color="primary"
            @click="editCertification"
          >
            Modifier
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mdiCertificateOutline } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Modifier une certification',
    titleTemplate: '%s - Jimbo',
  },
  components: {
  },
  data() {
    return {
      loading: true,
      dialog: false,
      certification: {
        company: this.$store.state.company._id,
        name: '',
        cost: 0,
        certifier: '',
      },
      icons: {
        mdiCertificateOutline,
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchCertif()])
        .then(values => {
          [this.certification] = values
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchCertif() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/certification/${this.$route.params.id}`)

      return response.data
    },
    deleteCertification() {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/certification/${this.$route.params.id}`)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Certification supprimée avec succès.',
            value: true,
          })
          this.$router.push({ name: 'certifications' })
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression de la certification',
            value: true,
          })
        })
    },
    editCertification() {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/certification/${this.$route.params.id}`, this.certification)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Certification modifiée avec succès.',
            value: true,
          })
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la modification de la certification',
            value: true,
          })
        })
    },
  },
}
</script>

<style>

</style>
